<template>
  <div id="demo-14vzn-page">
    <DemoVzn14 />
  </div>
</template>

<script>
import DemoVzn14 from '../entity/vzn14/DemoVzn14.vue'

export default {
  name: 'DemoVzn14View',
  components: { DemoVzn14 },
}
</script>
