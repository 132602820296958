<template>
  <v-btn
    :loading="loading"
    text
    :color="error ? 'error' : 'primary'"
    @click="exportDocx"
  >
    <v-icon left>mdi-download</v-icon>
    Сформировать заявку
  </v-btn>
</template>

<script>
import { toAge, toDate, shortFIO } from '../utils'

import {
  Document,
  Packer,
  Paragraph,
  AlignmentType,
  convertMillimetersToTwip,
  TextRun,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
} from 'docx'
import { saveAs } from 'file-saver'
import { isEmptyObject } from '@/lib/objects'

const DEFAULT_SIZE = 24

const DEFAULT_TABLE_MARGINS = () => ({
  top: convertMillimetersToTwip(0.5),
  bottom: convertMillimetersToTwip(0.5),
  left: convertMillimetersToTwip(1),
  right: convertMillimetersToTwip(1),
})

export default {
  name: 'bidDocx',
  data: () => ({
    loading: false,
    error: false,
  }),
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    async exportDocx() {
      this.error = false
      this.loading = true
      try {
        const fileName = `Заявка ${shortFIO(
          this.data?.fio ?? ''
        )} от ${this.getTimestamp()}.docx`

        const doc = new Document({
          styles: {
            default: {
              document: {
                paragraph: {
                  spacing: {
                    // межстрочный интервал 1.5 ???
                    line: 360,
                  },
                },
              },
            },
          },
          sections: [
            {
              properties: {
                page: {
                  //отступы страницы, Величина измерения-пункты. Требуется подогнать ее под сантиметры
                  margin: {
                    top: convertMillimetersToTwip(20), //2 сантимерта
                    right: convertMillimetersToTwip(15), //1.5 сантиметра
                    bottom: convertMillimetersToTwip(30), //3 сантиметра
                    left: convertMillimetersToTwip(20), //2 сантиметра
                  },
                },
              },
              children: [
                this.createParagraph(
                  'ЗАЯВКА НА ЛЕКАРСТВЕННОЕ ОБЕСПЕЧЕНИЕ ПАЦИЕНТА ЛЕКАРСТВЕННЫМИ ПРЕПАРАТАМИ ПЕРЕЧНЯ 14 ВЗН',
                  { alignment: AlignmentType.CENTER },
                  {},
                  24
                ),
                new Paragraph({ text: '', size: 22 }),
                this.createParagraph('1. Данные пациента', {}, {}, 22),
                new Table({
                  width: { size: 100, type: 'pct' },
                  margins: DEFAULT_TABLE_MARGINS(),
                  rows: this.getPacientTableRows(),
                }),
                new Paragraph({ text: '', size: 22 }),
                this.createParagraph('2. Данные заявки', {}, {}, 22),
                new Table({
                  width: { size: 100, type: 'pct' },
                  margins: DEFAULT_TABLE_MARGINS(),
                  rows: this.getBidTableRows(),
                }),
              ],
            },
          ],
        })
        await Packer.toBlob(doc).then(blob => saveAs(blob, fileName))
      } catch (err) {
        this.error = true
        console.error('Ошибка сохранения заявки: ', err)
        this.$emit('error', err)
      } finally {
        this.loading = false
      }
    },

    getPacientTableRows() {
      const headers = {
        subject: 'Субъект РФ',
        fio: 'ФИО',
        birthday: 'Дата рождения',
        age: 'Возраст',
        sex: 'Пол',
        registrDate: 'Дата включения в регистр',
      }
      const { birthday, registrDate, ...rest } = this.data
      const data = {
        age: toAge(birthday),
        birthday: toDate(birthday),
        registrDate: toDate(registrDate),
        ...rest,
      }
      return [
        this.createHeaderCellsInRow(headers),
        this.createDataRowCellsInRow(headers, data),
      ]
    },

    getBidTableRows() {
      const headers = {
        appYear: 'Период заявочной кампании',
        mkb10: 'Код заболевания по МКБ-10',
        nosology: 'Нозология',
        schemaMedicine: 'МНН 14 ВЗН',
      }
      return [
        this.createHeaderCellsInRow(headers),
        this.createDataRowCellsInRow(headers, this.data),
      ]
    },

    createHeaderCellsInRow(headers = {}) {
      const children = []
      for (const head in headers) {
        children.push(
          this.createTableCell({
            children: [
              this.createParagraph(
                headers[head],
                { alignment: AlignmentType.CENTER },
                { bold: true },
                22
              ),
            ],
            verticalAlign: VerticalAlign.CENTER,
          })
        )
      }
      return new TableRow({ children })
    },

    createDataRowCellsInRow(headers = {}, data = {}) {
      const children = []
      if (!isEmptyObject(data)) {
        for (const head in headers) {
          children.push(
            this.createTableCell({
              children: [
                this.createParagraph(
                  data?.[head] || ' - ',
                  { alignment: AlignmentType.CENTER },
                  {},
                  22
                ),
              ],
              verticalAlign: VerticalAlign.CENTER,
            })
          )
        }
      }
      return new TableRow({ children })
    },

    createTableCell({ children: content, ...options } = {}) {
      const children = content?.length ? content : [this.createParagraph('')]
      return new TableCell({ children, ...options })
    },
    /**
     * создание массива объектов TextRun с разделением по перенову строки .
     * @param text - текста,
     * @param options - опции текста,
     */
    createTextRuns(text = '', options = {}) {
      // подчищаем Unicode Character 'START OF TEXT' (U+0002)
      // режем на массив строк по \n
      const NOT_SAFE_IN_XML_1_0 =
        // eslint-disable-next-line no-control-regex
        /[^\x09\x0A\x0D\x20-\xFF\x85\xA0-\uD7FF\uE000-\uFDCF\uFDE0-\uFFFD]/gm

      return String(text)
        .replace(NOT_SAFE_IN_XML_1_0, '')
        .split('\n')
        .map(
          (text, idx) =>
            new TextRun({
              ...options,
              text,
              break: idx > 0 ? 1 : undefined,
            })
        )
    },
    /**
     * СОЗДАНИЕ ПАРАГРАФА.
     * @param text -ТЕКСТ ПАРАГРАФА,
     * @param options -ОПЦИИ ПАРАГРАФА.,
     * @param textRunOptions -ОПЦИИ ТЕКСТА.,
     */
    createParagraph(
      text,
      options = {},
      textRunOptions = {},
      size = DEFAULT_SIZE
    ) {
      const children = this.createTextRuns(text, { ...textRunOptions, size })

      return new Paragraph({
        ...options,
        children,
      })
    },
    getTimestamp() {
      const date = new Date()
      const addZero = v => (v.toString().length === 1 ? `0${v}` : v)
      const yyyy = date.getFullYear()
      const MM = addZero(date.getMonth() + 1)
      const dd = addZero(date.getDate())
      return `${dd}_${MM}_${yyyy}`
    },
  },
}
</script>
