<template>
  <!-- Режим - задаём вопрос  -->
  <v-timeline-item v-if="hasQuestion" color="primary">
    <v-list-item class="pl-2" :disabled="isLoading">
      <v-list-item-content class="pt-1">
        <v-list-item-title>
          <span class="text-wrap text-justify text-h6">
            <span class="text--disabled">{{ idx + 1 }}. </span>
            <span>{{ questionTitle }}</span
            >:
          </span>
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon class="pl-11" />
      <v-list-item-action v-if="isLoading">
        <v-progress-circular indeterminate />
      </v-list-item-action>
    </v-list-item>
    <!-- ответы -->
    <v-list class="ml-2 py-0" :disabled="isLoading">
      <v-list-item
        v-for="(ans, a_idx) in answers"
        :key="a_idx"
        @click="nextClick(a_idx)"
      >
        <v-list-item-icon><v-icon>mdi-chevron-right</v-icon></v-list-item-icon>
        <v-list-item-content :title="ans.note">
          <v-list-item-title class="text-wrap text-justify text-body-2">
            {{ ans.name }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action
          class="text-body-1 text--disabled font-weight-medium"
        >
          <v-list-item-action-text>
            {{ ans.code }}
          </v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <!-- Если есть ошибка загрузки -->
    <v-alert
      v-if="errorMessage"
      type="error"
      dense
      outlined
      dismissible
      class="mx-2 mb-0"
      close-icon="mdi-refresh"
      @input="refreshClick()"
    >
      {{ errorMessage }}
    </v-alert>
  </v-timeline-item>
  <!-- Режим - отображаем ответ  -->
  <v-timeline-item
    v-else-if="hasAnswer"
    small
    :color="iconColor"
    class="shift-icon-8"
  >
    <v-list-item
      :title="`Вернутся к выбору на шаг ${idx + 1}`"
      class="pl-2"
      @click="prevClick()"
    >
      <v-list-item-content class="pt-1">
        <v-list-item-title>
          <span
            class="text-wrap text-justify text-h6 text--secondary font-weight-regular"
          >
            <span class="text--disabled">{{ idx + 1 }}. </span>
            <span>{{ questionTitle }}</span
            >:
          </span>
        </v-list-item-title>
        <v-list-item-subtitle
          class="text-wrap text-justify pl-6 font-weight-medium"
        >
          {{ answerTitle }}
        </v-list-item-subtitle>
        <v-list-item-subtitle
          class="text-wrap text-justify pl-6 font-weight-regular"
        >
          {{ answerNote }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-icon
        class="text-body-1 text--secondary font-weight-medium"
        :title="answerTitle"
      >
        {{ answerCode }}
      </v-list-item-icon>
    </v-list-item>
  </v-timeline-item>
  <!-- Режим - Cхема -->
  <v-timeline-item
    v-else-if="schemes"
    color="success"
    small
    class="shift-icon-24"
  >
    <v-divider class="my-2" />
    <v-list-item class="pl-2">
      <v-list-item-content class="pt-1">
        <v-list-item-title>
          <span class="text-h5">
            <span class="text--disabled">Код: </span>
            <span class="font-weight-bold">{{ schemes.code }}</span>
          </span>
        </v-list-item-title>
        <v-list-item-subtitle
          v-if="hasSchemesDescription"
          class="text-pre-wrap text-justify pl-6 pt-2 font-weight-medium"
          >{{ schemesDescription }}</v-list-item-subtitle
        >
        <v-list-item-subtitle
          v-if="hasSchemesEffectiveness"
          class="text-pre-wrap text-justify pl-6 pt-3 font-weight-medium"
          ><span class="font-weight-light">Эффективность:</span><br />{{
            schemesEffectiveness
          }}</v-list-item-subtitle
        >
        <v-list-item-subtitle
          v-if="hasSchemesSource"
          class="text-pre-wrap text-justify pl-6 pt-1 font-weight-regular"
          ><span class="font-weight-light">Источник:</span><br />{{
            schemesSource
          }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>
    <!-- Схема ЛП 14 ВЗН -->
    <div v-if="hasMainSchemes" class="pl-8 text-h6">
      <span class="text-subtitle-2">Схема ЛП 14 ВЗН:</span>
      <v-radio-group
        v-model="medicine"
        class="ml-4"
        active-class="font-weight-bold"
        :messages="medicine?.smnn_code"
      >
        <template #message="{ message }">
          Код узла СМНН: <span class="font-weight-bold">{{ message }}</span>
        </template>
        <template v-for="(med, key) in schemes.main">
          <div class="d-flex justify-space-between" :key="key">
            <v-radio
              :value="med"
              :label="med.name"
              class="font-weight-medium"
            />
            <div
              v-if="med.cost"
              class="text-body-2 font-weight-light"
              :class="{ 'font-weight-medium': med === medicine }"
              title="Расчётная стоимость схемы"
            >
              {{ med.cost | toMoney }}
            </div>
          </div>
        </template>
      </v-radio-group>
    </div>
    <!-- Схема ЛП не 14 ВЗН (при наличии) -->
    <div v-if="hasOtherSchemes" class="pl-8 text-body-1">
      <span class="text-subtitle-2">Схема ЛП не 14 ВЗН:</span>
      <ul class="ml-4">
        <li v-for="(scheme, key) in schemes.other" :key="key">
          {{ scheme }}
        </li>
      </ul>
    </div>
  </v-timeline-item>
</template>

<script>
import { toMoney } from '../utils'

export default {
  name: 'quizItem.vue',
  props: {
    idx: Number,
    item: Object,
    answer: Number || null,
    getItems: {
      type: Function,
      default: null,
    },
    iconColor: {
      type: String,
      default: 'secondary',
    },
  },
  data: () => ({
    loading: false,
    message: '',
    medicine: null,
  }),
  watch: {
    item: 'loadItems',
    medicine: 'selectMedicine',
  },
  computed: {
    hasAnswer() {
      return (this.answer ?? -1) >= 0
    },
    hasQuestion() {
      return !this.hasAnswer && !this.schemes
    },
    questionTitle() {
      return this.item?.question
    },
    answers() {
      return this.item?.items
    },
    answerTitle() {
      return this.answers?.[this.answer]?.name
    },
    answerNote() {
      return this.answers?.[this.answer]?.note
    },
    answerCode() {
      return this.answers?.[this.answer]?.code
    },
    schemes() {
      return this.item?.scheme
    },
    schemesDescription() {
      return String(this.item?.scheme?.description || '').trim()
    },
    hasSchemesDescription() {
      return !!this.schemesDescription
    },
    schemesEffectiveness() {
      return String(this.item?.scheme?.effectiveness || '').trim()
    },
    hasSchemesEffectiveness() {
      return !!this.schemesEffectiveness
    },
    schemesSource() {
      return String(this.item?.scheme?.source || '').trim()
    },
    hasSchemesSource() {
      return !!this.schemesSource
    },
    hasMainSchemes() {
      return !!this.schemes?.main?.length
    },
    hasOtherSchemes() {
      return !!this.schemes?.other?.length
    },
    isLoading() {
      return this.loading
    },
    errorMessage() {
      return this.message
    },
  },
  async mounted() {
    await this.loadItems(this.item)

    if (this.hasMainSchemes && this.schemes.main.length === 1) {
      this.selectMedicine(this.schemes.main[0])
    }
  },
  filters: {
    toMoney,
  },
  methods: {
    prevClick() {
      this.$emit('prev')
    },
    nextClick(a_idx) {
      this.$emit('next', a_idx)
    },
    refreshClick() {
      this.message = ''
      this.loadItems(this.item)
    },
    async loadItems(item) {
      // если нужно подгрузить данные и есть метод
      if (item && item.items === null && this.getItems) {
        this.loading = true
        this.message = ''
        try {
          await this.getItems(item)
        } catch (error) {
          this.message = `Ошибка получения данных для "${item?.dict}": ${error}`
        } finally {
          this.loading = false
        }
      }
    },
    selectMedicine(medicine) {
      this.medicine = medicine
      this.$emit('select', this.schemes, this.medicine)
    },
  },
}
</script>

<style>
.shift-icon-8 .v-timeline-item__divider {
  padding-top: 8px;
}
.shift-icon-24 .v-timeline-item__divider {
  padding-top: 24px;
}
</style>
