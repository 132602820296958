<template>
  <v-card v-bind="$attrs">
    <v-card-title v-if="!hideTitle" class="text-h5">{{ title }}</v-card-title>
    <v-card-text>
      <slot name="top" />
      <v-list dense class="py-0" :disabled="loading">
        <v-timeline align-top dense :class="timeLineClass">
          <slot name="timeline-top" />
          <v-slide-y-transition group>
            <quizItem
              v-for="(qst, idx) in items"
              :key="idx"
              :idx="idx"
              :item="qst.item"
              :answer="qst.answer"
              :get-items="loadItems"
              class="mt-0 pb-1"
              :class="{ 'tm-mobile': mobile }"
              :icon-color="iconColor"
              @prev="prev(idx)"
              @next="a_idx => next(idx, a_idx)"
              @select="onSelect"
            />
          </v-slide-y-transition>
          <v-slide-y-transition>
            <v-timeline-item v-if="codeProcessing" hide-dot>
              <v-divider class="my-2" />
              <span class="text--disabled">
                Построение кода:
                <span class="text--secondary font-weight-bold">{{ code }}</span>
                ...
              </span>
            </v-timeline-item>
          </v-slide-y-transition>
          <slot name="timeline-bottom" />
        </v-timeline>
      </v-list>
      <slot name="bottom" />
    </v-card-text>
    <v-card-actions>
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
import quizItem from './quizItem.vue'

export default {
  components: { quizItem },
  name: 'Vzn14Quizer',
  inheritAttrs: false,
  props: {
    quiz: {
      type: Object,
      required: true,
    },
    getItems: {
      type: Function,
      default: null,
    },
    iconColor: {
      type: String,
      default: 'secondary',
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [],
    loading: false,
  }),
  created() {
    this.items.push({ item: this.quiz, answer: null })
  },
  computed: {
    mobile() {
      return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)
    },
    timeLineClass() {
      return { 'tm-mobile': this.mobile }
    },
    title() {
      return this.quiz?.name || ''
    },
    code() {
      return this.items.reduce(
        (code, { item }) => `${code}${item?.code || ''}${item?.sc || ''}`,
        ''
      )
    },
    codeProcessing() {
      return (
        this.items.length > 1 &&
        !this.items[this.items.length - 1]?.item?.scheme
      )
    },
  },
  methods: {
    /** переход к вопросу idx */
    prev(idx) {
      if (idx < this.items.length) {
        this.items[idx].answer = null
        this.items.splice(idx + 1)
        const last = this.items[idx - 1]
        const item = last?.item.items[last.answer]
        this.onAnswer(idx - 1, item)
        this.onSelect(null, null)
      }
    },
    /** переход к следующему вопросу после ответа item */
    next(idx, a_idx) {
      this.prev(idx)
      const last = this.items[idx]
      const item = last?.item.items[a_idx]
      if (last && item) {
        // запомнили ответ
        last.answer = a_idx
        // перешли на новый вопрос
        this.items.push({ item, answer: null })
        // уведомление
        this.onAnswer(idx, item)
      }
      this.onSelect(null, null)
    },
    async loadItems(item) {
      if (item && this.getItems) {
        this.loading = true
        try {
          await this.getItems(item)
        } finally {
          this.loading = false
        }
      }
    },
    onSelect(scheme, medicine) {
      this.$emit('select', scheme, medicine)
    },
    onAnswer(idx, item) {
      this.$emit('answer', idx, item)
    },
  },
}
</script>

<style scoped>
.tm-mobile {
  left: -40px;
}
</style>
