<template>
  <v-container class="mt-2">
    <v-overlay v-if="loading" :value="1">
      <div class="mb-3">Подготовка демо ...</div>
      <v-progress-linear indeterminate size="75" rounded color="white" />
    </v-overlay>
    <v-row v-if="message">
      <v-col md="10" offset-md="1">
        <v-alert type="error" outlined
          >Внимание произошла ошибка: {{ message }}</v-alert
        >
      </v-col>
    </v-row>
    <!-- Демка -->
    <v-row v-else>
      <v-col md="8" offset-md="2">
        <Vzn14Quizer
          v-if="data"
          :quiz="data"
          :get-items="loadItems"
          :icon-color="iconColor"
          elevation="1"
          shaped
          @select="onSelect"
          @answer="onAnswer"
        >
          <template #timeline-top>
            <!-- Субъект РФ -->
            <v-timeline-item
              :color="iconColor"
              icon-color="secondary"
              icon="mdi-map"
            >
              <v-combobox
                label="Субъект РФ"
                v-model="app.subject"
                :items="appSubjects"
                :loading="appSubjectsLoading"
                :error-messages="appSubjectsError"
              />
            </v-timeline-item>
            <!-- Период заявочной кампании -->
            <v-timeline-item
              :color="iconColor"
              icon-color="secondary"
              icon="mdi-calendar-range"
            >
              <v-combobox
                label="Период заявочной кампании"
                v-model="app.appYear"
                :items="appYears"
              />
            </v-timeline-item>
            <!-- ФИО -->
            <v-timeline-item
              :color="iconColor"
              icon-color="secondary"
              icon="mdi-account-outline"
            >
              <v-text-field v-model.trim="app.fio" label="ФИО" />
            </v-timeline-item>
            <!-- Дата рождения -->
            <v-timeline-item
              :color="iconColor"
              icon-color="secondary"
              icon="mdi-calendar"
            >
              <v-menu
                v-model="menuBirthday"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="app.birthday | toDate"
                    label="Дата рождения"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="app.birthday"
                  @input="menuBirthday = false"
                />
              </v-menu>
            </v-timeline-item>
            <!-- Пол -->
            <v-timeline-item
              :color="iconColor"
              icon-color="secondary"
              icon="mdi-gender-male-female"
            >
              <v-combobox label="Пол" v-model="app.sex" :items="appSexs" />
            </v-timeline-item>
            <!-- Дата включения в регистр -->
            <v-timeline-item
              :color="iconColor"
              icon-color="secondary"
              icon="mdi-calendar-account"
            >
              <v-menu
                v-model="menuRegistrDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="app.registrDate | toDate"
                    label="Дата включения в регистр"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="app.registrDate"
                  @input="menuRegistrDate = false"
                />
              </v-menu>
            </v-timeline-item>
            <v-timeline-item
              :color="iconColor"
              icon-color="secondary"
              icon="mdi-account"
              class="text-h6 font-weight-regular"
            >
              Модель пациента
            </v-timeline-item>
          </template>
          <template v-if="formDone" #bottom>
            <v-divider class="mt-5" />
          </template>
          <template v-if="formDone" #actions>
            <v-layout justify-center>
              <bid-docx :data="app" class="mb-2" />
            </v-layout>
          </template>
        </Vzn14Quizer>
      </v-col>
    </v-row>
    <!-- Отстут -->
    <div style="height: 50vh" />
  </v-container>
</template>

<script>
import api from '@/lib/axiosApi'
import { toDate, toAge } from './utils'
import Vzn14Quizer from './Vzn14Quizer'
import BidDocx from './components/bidDocx'

const vzn14Api = 'vzn14/demo'

export default {
  components: { Vzn14Quizer, BidDocx },
  name: 'DemoVzn14',
  data: () => ({
    loading: true,
    data: null,
    message: '',
    iconColor: 'grey lighten-3',
    // заявка
    app: {
      subject: '',
      appYear: null,
      fio: '',
      birthday: '',
      sex: '',
      registrDate: '',
      // схема
      mkb10: '',
      nosology: '',
      schemaCode: '',
      schemaMedicine: '',
    },
    appSexs: ['Мужской', 'Женский'],
    appSubjects: null,
    appSubjectsLoading: false,
    appSubjectsError: '',
    menuBirthday: false,
    menuRegistrDate: false,
    loadingDocx: false,
  }),
  computed: {
    appYears() {
      const n = new Date()
      const year = n.getFullYear()
      return [year - 1, year, year + 1, year + 2]
    },
    formDone() {
      return (
        // достаточно выбранного ЛП
        this.app.schemaMedicine &&
        // и остальных полей по списку
        this.app.subject &&
        this.app.fio &&
        this.app.appYear &&
        this.app.birthday &&
        this.app.sex &&
        this.app.registrDate
      )
    },
  },
  created() {
    this.loadSubjects()
    this.init()
  },
  filters: {
    toDate,
    toAge,
  },
  methods: {
    async init() {
      this.loading = true
      this.message = ''
      try {
        this.data = await api.get(vzn14Api)
      } catch (error) {
        this.message = `Ошибка получения данных: ${error}`
      } finally {
        this.loading = false
      }
    },
    async loadItems(item) {
      if (item && !item.items && item.id) {
        item.items = await api.get(`${vzn14Api}/${item.id}`)
      }
    },
    async loadSubjects() {
      try {
        this.appSubjectsLoading = true
        this.appSubjectsError = ''
        this.appSubjects = await api.get('dict/regions/list?direct')
      } catch (error) {
        this.appSubjectsError = `Ошибка получения справочника субъектов`
      } finally {
        this.appSubjectsLoading = false
      }
    },
    onSelect(item, medicine) {
      this.app.schemaCode = item?.code
      this.app.schemaMedicine = medicine?.name
    },
    onAnswer(idx, item) {
      switch (idx) {
        case 0:
          this.app.mkb10 = ''
          this.app.nosology = ''
          break
        case 1:
          this.app.mkb10 = item.code
          this.app.nosology = ''
          break

        case 2:
          this.app.nosology = item.name
          break
      }
    },
  },
}
</script>
