/** Делаем заглавной первую букву  */
export function capitalize(value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

/** Формат отображения валюты с разделителями и запятой (округление до сотых, два знака после запятой)
 * @param {number} value - значение
 * @param {boolean} [onlyDigits=false] - только цифры илаче денежный формат с символом рубля
 */
export function toMoney(value, onlyDigits = false) {
  const formatter = new Intl.NumberFormat(
    'ru-RU',
    onlyDigits
      ? {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      : {
          style: 'currency',
          currency: 'RUB',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
  )
  return formatter.format(Number(value || 0))
}

/** из Иванов Пётр Сергеевич делаем Иванов П.С. */
export function shortFIO(fullFIO) {
  // если пусто то пусто
  if (!fullFIO || typeof fullFIO !== 'string') return fullFIO

  const fio = fullFIO.trim().split(' ')
  // если просто Администратор, или без отчества - выходим отсюда
  // выйдем если пришло Фамилия И.О., Фамилия Имя
  if (fio.length <= 2) return fullFIO

  const patr = capitalize(fio.pop().charAt(0))
  const name = capitalize(fio.pop().charAt(0))
  const surname = capitalize(fio.join('-'))

  return `${surname} ${name}.${patr}.`
}

export function toDate(value) {
  if (!value) return ''
  const dt = new Date(value)
  return dt.toLocaleDateString()
}

export function toAge(value) {
  if (!value) return 0
  const simpleAge = new Date().getFullYear() - new Date(value).getFullYear()
  return simpleAge
}
